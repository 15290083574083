import React from "react";
import AdminLedger from "./AdminLedger";
import UserLedger from "./UserLedger";

const Ledger = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  return isAdmin ? <AdminLedger /> : <UserLedger />;
};

export default Ledger;
