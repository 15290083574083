import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import "../../../css/sidebar.css";
import { NoAuth } from "../../../api/noAuth";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AddPatients = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [hospitals, setHospitals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getHospitals = async () => {
    setIsLoading(true);
    const response = await NoAuth.getHospitals();
    setIsLoading(false);
    setHospitals(response.data.data);
  };

  const handleAddPatient = async (data) => {
    await HospitalAPI.addPatients(data);
    reset();
  };

  useEffect(() => {
    getHospitals();
  }, []);
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Patient</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddPatient)}>
                    {isLoading ? <span className="loader"></span> : ""}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Select Hospital
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="hospital_id"
                          {...register("hospital_id", {
                            required: "Hospital is Required.",
                          })}
                          className="form-control input-height"
                        >
                          <option value="">Select Hospital</option>
                          {hospitals.map((hospital, i) => (
                            <option key={i} value={hospital.id}>
                              {hospital.name}
                            </option>
                          ))}
                        </select>
                        {errors.hospital_id && (
                          <p style={{ color: "red" }}>
                            {errors.hospital_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Patient Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("name", {
                            required: "Patient Name is Required.",
                          })}
                          className="form-control"
                          name="name"
                          id="inputEmail3"
                          placeholder="Enter Patient Name"
                        />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatients;
