import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "./Navbar";
import "../../css/sidebar.css";
import { UserAPI } from "../../api/userAPI";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async (data) => {
    try {
      setIsLoading(true);
      await UserAPI.changePassword(data);
      reset();
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Change Password</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleChangePassword)}>
                    {isLoading ? <span className="loader"></span> : ""}

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Password
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="password"
                          name="password"
                          {...register("password", {
                            required: "Password is Required.",
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/,
                              message:
                                "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one special character, and one number.",
                            },
                          })}
                          className="form-control"
                          placeholder="Enter your password"
                        />
                        {errors.password && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Confirm Password
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="password"
                          name="confirmPassword"
                          {...register("confirmPassword", {
                            required: "Confirm Password is Required.",
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/,
                              message:
                                "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one special character, and one number.",
                            },
                          })}
                          className="form-control"
                          placeholder="Re-enter Password To Reset"
                        />
                        {errors.confirmPassword && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.confirmPassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
