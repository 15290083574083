import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "./Navbar";
import { NoAuth } from "../../api/noAuth";
import "../../css/sidebar.css";
import { UserAPI } from "../../api/userAPI";

const AddUser = () => {
  const [roles, setRoles] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, key) => {
    const value = e.target.value;
    let trimmedValue;
    if (key === "mobile_no") {
      trimmedValue = value.slice(0, 10);
      setValue("mobile_no", trimmedValue);
    }
  };

  const getRoles = async () => {
    const response = await NoAuth.getRoles();
    setRoles(response.data.data);
  };

  const handleAddUser = async (data) => {
    setIsLoading(true);
    await UserAPI.addUser(data);
    reset();
    setIsLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New User</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddUser)}>
                    {isLoading ? <span className="loader"></span> : ""}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Role Id
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="role_id"
                          name="role_id"
                          className="form-control"
                          {...register("role_id", {
                            required: "Role is Required.",
                          })}
                        >
                          <option value="">Select Role</option>
                          {roles.map((role, i) => (
                            <option key={i} value={role.id}>
                              {role.role}
                            </option>
                          ))}
                        </select>
                        {errors.role_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.role_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("name", {
                            required: "Name is Required.",
                          })}
                          className="form-control"
                          name="name"
                          id="inputEmail3"
                          placeholder="Enter Name"
                        />
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Mobile No
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("mobile_no", {
                            required: "Mobile Number is Required.",
                            pattern: {
                              value: /^\d{10}$/,
                              message:
                                "Please enter a valid 10-digit Mobile Number",
                            },
                          })}
                          onChange={(e) => handleChange(e, "mobile_no")}
                          className="form-control"
                          name="mobile_no"
                          id="inputEmail3"
                          placeholder="Enter Mobile No."
                        />
                        {errors.mobile_no && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.mobile_no.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Email
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="email"
                          {...register("email", {
                            required: "Email is Required.",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: "Please enter a valid email address",
                            },
                          })}
                          className="form-control"
                          name="email"
                          id="inputEmail3"
                          placeholder="Enter Email"
                        />
                        {errors.email && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Password
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="password"
                          name="password"
                          {...register("password", {
                            required: "Password is Required.",
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/,
                              message:
                                "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one special character, and one number.",
                            },
                          })}
                          className="form-control"
                          placeholder="Enter your password"
                        />
                        {errors.password && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
