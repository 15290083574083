import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import "../../../css/sidebar.css";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AddRoles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleAddRoles = async (data) => {
    setIsLoading(true);
    await HospitalAPI.addRoles(data);
    reset();
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Roles</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddRoles)}>
                    {isLoading && <span className="loader"></span>}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Role
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>

                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("role", {
                            required: "Role is Required.",
                          })}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter role name"
                        />
                        {errors.role && (
                          <p style={{ color: "red" }}>{errors.role.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-primary btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRoles;
