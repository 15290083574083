import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import Pagination from "../../../services/Pagination";
import { showAlert } from "../../../services/alert_service";
import { UserAPI } from "../../../api/userAPI";

const UserLedger = () => {
  const [ledgerData, setLedgerData] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const getLedgerData = async () => {
    setIsLoading(true);
    const response = await UserAPI.userLedger()
    setAmounts(response.amount);
    setLedgerData(response.data);
    setIsLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const currentData = () => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return ledgerData
      .filter(
        (ledger) =>
          ledger.transaction_type
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ledger.appointment?.hospital?.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ledger.appointment?.patient?.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          ledger.type.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .slice(firstPageIndex, lastPageIndex);
  };

  useEffect(() => {
    getLedgerData();
  }, []);

  useEffect(() => {
    const filtered = ledgerData.filter((ledger) =>
      ledger.transaction_type.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCurrentPage(1);
  }, [searchQuery, ledgerData]);


  return (
    <div>
      <Navbar />

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Ledger</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  {amounts && amounts.length > 0 && (
                    <>
                      <div
                        className="mb-3 mb-xl-0"
                        style={{ marginRight: "10px" }}
                      >
                        <span className="btn btn-dark">Total Received</span>
                        <span className="form-control">
                          {amounts[0].totalDr}
                        </span>
                      </div>
                      <div
                        className="mb-3 mb-xl-0"
                        style={{ marginRight: "10px" }}
                      >
                        <span className="btn btn-dark">Total Spent</span>
                        <span className="form-control">
                          {amounts[0].totalCr}
                        </span>
                      </div>
                      <div
                        className="mb-3 mb-xl-0"
                        style={{ marginRight: "10px" }}
                      >
                        <span className="btn btn-dark">Total Amount</span>
                        <span className="form-control">
                          {amounts[0].totalAmount}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        onChange={handleSearch}
                        className="form-control"
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-icon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Date</th>
                          <th>Transaction Type</th>
                          <th>Hospital</th>
                          <th>Patient</th>
                          <th>Amount</th>
                          <th>Type</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData().map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>
                                {(currentPage - 1) * itemsPerPage + i + 1}
                              </td>
                              <td>{list.createdAt.substring(0, 10)}</td>
                              <td>{list.transaction_type}</td>
                              <td>{list.appointment?.hospital?.name || "-"}</td>
                              <td>{list.appointment?.patient?.name || "-"}</td>
                              <td>{atob(list.amount) || "-"}</td>
                              <td>{list.type}</td>
                              <td>{list.note}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLedger;
