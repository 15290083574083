import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { Modal, ModalHeader } from "reactstrap";
import Pagination from "../../../services/Pagination";
import { HospitalAPI } from "../../../api/hospitalAPI";
import { NoAuth } from "../../../api/noAuth";

const AdminLedger = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const [ledgerData, setLedgerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pendingAmt, setPendingAmt] = useState("");
  const [hospitalId, setHospitalId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const watchPaymentType = watch("payment_type");
  const itemsPerPage = 10;

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const appointments = permissions.find(
    (item) => item.table_name === "appointments"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getLedgerData = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getAdminLedger(
      currentPage,
      itemsPerPage,
      hospitalId
    );
    setLedgerData(response.data.data);
    setFilteredData(response.data.data);
    setTotalPages(response.data.total_page);
    setPendingAmt(response.data.totalAmount);
    setIsLoading(false);
  };

  const getUsers = async () => {
    const response = await NoAuth.getUsers();
    setUsers(response.data.data);
  };

  const getHospitals = async () => {
    const response = await NoAuth.getHospitals();
    setHospitals(response.data.data);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const setEditModal = (id) => {
    setModal(true);
    const selected = ledgerData.find((item) => item.id === id);
    setValue("appointment_id", selected.appointment_id);
    setValue("patient_name", selected.appointment.patient.name);
  };

  const handleEditSubmit = async (data) => {
    delete data.patient_name;
    console.log("payload", data);
    data.adjustment_amount = parseInt(data.adjustment_amount);
    setIsLoading(true);
    await HospitalAPI.makePayment(data);
    setModal(!modal);
    getLedgerData();
    setIsLoading(false);
    reset();
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const lowercasedFilter = e.target.value.toLowerCase();
    const filteredData = ledgerData.filter((item) => {
      return item.appointment.patient.name
        .toLowerCase()
        .includes(lowercasedFilter);
    });
    setFilteredData(filteredData);
  };

  useEffect(() => {
    getHospitals();
    getUsers();
  }, []);

  useEffect(() => {
    getLedgerData();
    getUsers();
    getHospitals();
  }, [currentPage, hospitalId]);

  useEffect(() => {
    const filtered = ledgerData.filter((item) =>
      item.appointment.patient.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, ledgerData]);

  useEffect(() => {
    getLedgerData();
  }, [currentPage, hospitalId]);

  useEffect(() => {
    handleSearch({ target: { value: searchQuery } });
  }, [ledgerData]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Collection</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "42px" }}
                        >
                          Patient Name
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            name="patient_name"
                            id="patient_name"
                            disabled
                            className="form-control"
                            style={{ width: "300px", marginTop: "10px" }}
                            {...register("patient_name", {
                              required: "Patient Name is Required.",
                            })}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Collected By
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          id="collected_by"
                          name="collected_by"
                          {...register("collected_by", {
                            required: "Collected By is Required.",
                          })}
                          className="form-control input-height"
                          style={{ width: "300px", marginTop: "10px" }}
                        >
                          <option value="">Select...</option>
                          {users.map((user, i) => (
                            <option key={i} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                        {errors.collected_by && (
                          <p className="edit_ledger">
                            {errors.collected_by.message}
                          </p>
                        )}
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "42px" }}
                        >
                          Adjustment Amount
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="number"
                            id="adjustment_amount"
                            {...register("adjustment_amount", {
                              required: "Adjustment Amount is Required.",
                            })}
                            name="adjustment_amount"
                            className="form-control"
                            style={{ width: "300px", marginTop: "10px" }}
                            placeholder="Adjustment Amount"
                          />
                          {errors.adjustment_amount && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.adjustment_amount.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Payment Type
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="payment_type"
                          id="payment_type"
                          {...register("payment_type", {
                            required: "Payment Type is Required.",
                          })}
                          className="form-control input-height"
                          style={{ width: "300px", marginTop: "10px" }}
                        >
                          <option value="">Select types...</option>
                          <option value="cheque">Cheque</option>
                          <option value="cash">Cash</option>
                        </select>
                        {errors.payment_type && (
                          <p className="edit_ledger">
                            {errors.payment_type.message}
                          </p>
                        )}
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          System User?
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          id="isSystemUser"
                          name="isSystemUser"
                          {...register("isSystemUser", {
                            required: "System User? field is Required.",
                          })}
                          className="form-control input-height"
                          style={{ width: "300px", marginTop: "10px" }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "42px" }}
                        >
                          Note
                          {watchPaymentType === "cheque" && (
                            <span
                              className="text-danger"
                              style={{ margin: "4px" }}
                            >
                              *
                            </span>
                          )}
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            name="note"
                            {...register("note", {
                              required:
                                watchPaymentType === "cheque"
                                  ? "Note is required"
                                  : false,
                            })}
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter Note"
                          />
                          {errors.note && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.note.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{
                              backgroundColor: "#82b440",
                              marginRight: "30px",
                            }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => setModal(false)}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Ledger</h1>
              </div>
              {pendingAmt !== null && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <span className="btn btn-dark">Pending Collection</span>
                      <span className="form-control">{atob(pendingAmt)}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <select
                        value={hospitalId}
                        name="hospitalId"
                        onChange={(e) => setHospitalId(e.target.value)}
                        className="form-control input-height"
                      >
                        <option value="">Select Hospitals...</option>
                        {hospitals &&
                          hospitals.map((hospital, index) => (
                            <option key={index} value={hospital.id}>
                              {hospital.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control"
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-icon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : filteredData.length === 0 ? (
                <p>No Records Found.</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Patient Name</th>
                          <th>Appointment Date</th>
                          <th>Amount</th>
                          <th>Adjustment Amount</th>
                          {(isAdmin || appointments?.edit) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData &&
                          filteredData.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.appointment.patient.name}</td>
                                <td>{list.appointment.date}</td>
                                <td>{atob(list.amount)}</td>
                                <td>
                                  {list.appointment.adjustment_amount
                                    ? atob(list.appointment.adjustment_amount)
                                    : ""}
                                </td>
                                {(isAdmin || appointments?.edit) && (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLedger;
