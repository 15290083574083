import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import Pagination from "../../services/Pagination";
import { showAlert } from "../../services/alert_service";

const AllTransactions = () => {
  const [transactionsList, setTransactionsList] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getTransactions = async () => {
    await getMethod(`${Apiurl.transaction}`, {
      page: currentPage,
      pageSize: 5,
    })
      .then((res) => {
        setTransactionsList(res.data.data);
        setTotalPages(res.data.total_page);
      })
      .catch((error) => {
        console.log("error" + error);
        showAlert(error.message, "error");
      });
  };

  useEffect(() => {
    getTransactions();
  }, [currentPage]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Transactions</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {transactionsList.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="card">
                    <div className="body">
                      <form>
                        <div className="input-group">
                          <input
                            type="text"
                            name="search"
                            className="form-control"
                            placeholder="Search here..."
                          />
                          <div className="input-group-append">
                            <span className="input-group-text" id="search-mail">
                              <i className="fa fa-search"></i>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Hospital</th>
                          <th>Patient</th>
                          <th>Collected By</th>
                          <th>isSystemUser</th>
                          <th>Payment Type</th>
                          <th>Amount</th>
                          <th>Dr/Cr</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionsList &&
                          transactionsList.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.hospital.name}</td>
                                <td>{list.appointment.patient.name}</td>
                                {list.users ? (
                                  <td>{list.users.name}</td>
                                ) : (
                                  <td>-</td>
                                )}

                                <td>{list.isSystemUser ? "Yes" : "No"}</td>
                                <td>{list.payment_type}</td>
                                <td>{atob(list.amount)}</td>
                                <td>{list.type}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTransactions;
