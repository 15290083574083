import { api } from "./config";
import { toast } from "react-toastify";

export const UserAPI = {
  async login(data) {
    const response = await api.post("/user/login", data);
    toast.success(response.data?.message);
    return response.data;
  },
  async addUser(data) {
    const response = await api.post("/user/role", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getUsers(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/user/role?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  async updateUser(userId, data) {
    const response = await api.put(`/user/role/?id=${userId}`, data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async deleteUser(userId) {
    const response = await api.delete(`/user/role/?id=${userId}`);
    console.log("response delete", response.data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  async getUserCollections() {
    const response = await api.get(`/collection`);
    return response?.data;
  },
  async getCollectionPerUser(userId) {
    const response = await api.get(
      `/collection/collections?collected_by=${userId}`
    );
    return response?.data;
  },
  async transferMoney(data) {
    const response = await api.post(`/transfer`, data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async userLedger() {
    const response = await api.post(`/collection/userledger`);
    console.log("response", response.data.data);
    return response?.data;
  },
  async getDashboard() {
    const response = await api.get(`/user/dashboard`);
    return response.data;
  },
  async changePassword(payload) {
    const response = await api.put(`/user/change_password`, payload);
     toast.success(response?.data?.message);
    return response?.data || {};
  },
};
