import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import Hospitals from "./components/pages/Hospitals";
import UserCollections from "./components/pages/UserCollections";
import AddPayments from "./components/pages/AddPayments";
import Users from "./components/pages/Users";
import AddUser from "./components/pages/AddUser";
import AddHospital from "./components/pages/AddHospital";
import AddPatients from "./components/pages/patients/AddPatients";
import AllPatients from "./components/pages/patients/AllPatients";
import AddTypes from "./components/pages/appointment_types/AddTypes";
import AllTypes from "./components/pages/appointment_types/AllTypes";
import AddAppointment from "./components/pages/appointments/AddAppointment";
import Appointments from "./components/pages/appointments/Appointments";
import Roles from "./components/pages/roles/Roles";
import AddRoles from "./components/pages/roles/AddRoles";
import AllTransactions from "./components/pages/AllTransactions";
import AccessControl from "./services/AccessControl";
import AddExpense from "./components/pages/expense/AddExpense";
import AddExpenseType from "./components/pages/expense_types/AddExpenseType";
import AllExpenseType from "./components/pages/expense_types/AllExpenseType";
import AllExpense from "./components/pages/expense/AllExpense";
import AddPolicy from "./components/pages/policies/AddPolicy";
import AllPolicies from "./components/pages/policies/AllPolicies";
import AddIncomeType from "./components/pages/income_types/AddIncomeType";
import IncomeType from "./components/pages/income_types/IncomeTypes";
import AddIncome from "./components/pages/income/AddIncome";
import Incomes from "./components/pages/income/Incomes";
import Transfer from "./components/pages/Transfer";
import Dashboard from "./components/pages/Dashboard";
import Ledger from "./components/pages/ledger/Ledger";
import Permissions from "./components/pages/roles/Permissions";
import AddAgency from "./components/pages/agency/AddAgency";
import AgencyList from "./components/pages/agency/AgencyList";
import AddInventory from "./components/pages/inventory/AddInventory";
import Inventory from "./components/pages/inventory/Inventory";
import DueBills from "./components/pages/due_bills/dueBills";
import AddDueBills from "./components/pages/due_bills/AddDueBills";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./components/pages/ChangePassword";

function App() {
  const isLoggedIn = localStorage.getItem("token");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/" replace /> : <Login />}
          ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route element={<AccessControl />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/addusers" element={<AddUser />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/addroles" element={<AddRoles />} />
            <Route path="/patients" element={<AllPatients />} />
            <Route path="/addpatient" element={<AddPatients />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/addappointments" element={<AddAppointment />} />
            <Route path="/alltypes" element={<AllTypes />} />
            <Route path="/addtypes" element={<AddTypes />} />
            <Route path="/hospitals" element={<Hospitals />} />
            <Route path="/addhospital" element={<AddHospital />} />
            <Route path="/addpayments" element={<AddPayments />} />
            <Route path="/ledger" element={<Ledger />} />
            <Route path="/user-collections" element={<UserCollections />} />
            <Route path="/alltransactions" element={<AllTransactions />} />
            <Route path="/addexpensetype" element={<AddExpenseType />} />
            <Route path="/allExpensetype" element={<AllExpenseType />} />
            <Route path="/addexpense" element={<AddExpense />} />
            <Route path="/allexpense" element={<AllExpense />} />
            <Route path="/addpolicy" element={<AddPolicy />} />
            <Route path="/allpolicies" element={<AllPolicies />} />
            <Route path="/addincometype" element={<AddIncomeType />} />
            <Route path="/allincometype" element={<IncomeType />} />
            <Route path="/addincome" element={<AddIncome />} />
            <Route path="/allincome" element={<Incomes />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/permissions" element={<Permissions />} />
            <Route path="/add-agency" element={<AddAgency />} />
            <Route path="/agency" element={<AgencyList />} />
            <Route path="/add-inventory" element={<AddInventory />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/due-bills" element={<DueBills />} />
            <Route path="/add-due-bill" element={<AddDueBills />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer theme="light" autoClose={1500} />
    </>
  );
}

export default App;
