import { api } from "./config";
import { toast } from "react-toastify";

export const HospitalAPI = {
  // appointment
  async addAppointment(data) {
    const response = await api.post("/appointment", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getAppointments(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/appointment?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}&appointment_status=pending`
    );
    return response?.data;
  },
  async updateAppointment(userId, data) {
    const response = await api.put(`/appointment/?id=${userId}`, data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  // ledger
  async getAdminLedger(currentPage, pageSize, hospitalId) {
    const response = await api.get(
      `/appointment/ledger?page=${currentPage}&pageSize=${pageSize}&hospital_id=${hospitalId}`
    );
    return response?.data;
  },
  // payment of appointment
  async makePayment(data) {
    const response = await api.post("/appointment/payment", data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  // income
  async addIncome(data) {
    const response = await api.post("/income", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getIncome(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/income?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response.data;
  },
  //expense
  async addExpense(data) {
    const response = await api.post("/expense", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getExpense(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/expense?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response.data;
  },
  // hospital
  async addHospital(data) {
    const response = await api.post("/hospital", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getHospitals(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/hospital?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  async deleteHospital(id) {
    const response = await api.delete(`/hospital/?id=${id}`);
    console.log("response delete", response.data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  async updateHospital(id, data) {
    const response = await api.put(`/hospital/?id=${id}`, data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  // patients
  async addPatients(data) {
    const response = await api.post("/patient", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getPatients(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/patient?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  async deletePatients(id) {
    const response = await api.delete(`/patient/?id=${id}`);
    console.log("response delete", response.data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  async updatePatients(id, data) {
    const response = await api.put(`/patient/?id=${id}`, data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  // inventory
  async addInventory(data) {
    const response = await api.post("/inventory", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getInventory(currentPage, pageSize, searchQuery, agencyId) {
    const response = await api.get(
      `/inventory?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}&agency_id=${agencyId}`
    );
    return response?.data;
  },
  async updateInventory(id, data) {
    const response = await api.put(`/inventory?id=${id}`, data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  // agency
  async addAgency(data) {
    const response = await api.post("/agency", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getAgencies(currentPage, pageSize, searchQuery, agencyId) {
    const response = await api.get(
      `/agency?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}&agency_id=${agencyId}`
    );
    return response?.data;
  },
  // due-bills
  async addDueBills(data) {
    const response = await api.post("/due-income", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getDueBills(currentPage, pageSize, searchQuery, agencyId) {
    const response = await api.get(
      `/due-income?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}&agency_id=${agencyId}`
    );
    return response?.data;
  },
  async updateDueBills(id, data) {
    const response = await api.put(`/due-income?id=${id}`, data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  // appointment-type
  async addAppointmentType(data) {
    const response = await api.post("/appointment-type", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getAppointmentTypes(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/appointment-type?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  async updateAppointmentType(id, data) {
    const response = await api.put(`/appointment-type?id=${id}`, data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  async deleteAppointmentType(id) {
    const response = await api.delete(`/appointment-type/?id=${id}`);
    toast.success(response?.data?.message);
    return response?.data;
  },
  //roles
  async addRoles(data) {
    const response = await api.post("/role", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getRoles(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/role?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  async updateRoles(id, data) {
    const response = await api.put(`/role?id=${id}`, data);
    toast.success(response?.data?.message);
    return response?.data;
  },
  async deleteRoles(id) {
    const response = await api.delete(`/role/?id=${id}`);
    toast.success(response?.data?.message);
    return response?.data;
  },
  // expense type
  async addExpenseType(data) {
    const response = await api.post("/expense-type", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getExpenseType(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/expense-type?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  async addIncomeType(data) {
    const response = await api.post("/income-type", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getIncomeType(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/income-type?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },
  //getPermissions
  async getPermissions(roleId) {
    console.log("roleID", roleId);
    const response = await api.get(`/rolePermit/role?role_id=${roleId}`);
    console.log("response", response.data);
    return response?.data;
  },
  // policy
  async addPolicy(data) {
    const response = await api.post("/policy", data);
    toast.success(response?.data?.data?.message);
    return response?.data;
  },
  async getPolicies(currentPage, pageSize, searchQuery) {
    const response = await api.get(
      `/policy?page=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`
    );
    return response?.data;
  },

};
