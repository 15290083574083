import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AllExpense = () => {
  const [expenseList, setExpenseList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10

  const getExpenseList = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getExpense(currentPage, itemsPerPage, searchQuery)
    setExpenseList(response.data.data);
    setTotalPages(response.data.total_page);
    setIsLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getExpenseList();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div class="container-fluid">
          <div class="block-header">
            <div class="row clearfix">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>All Expenses</h1>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div class="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div class="mb-3 mb-xl-0">
                    <Link to="/addexpense">
                      <span class="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-sm-12">
              <div class="card">
                <div class="body">
                  <form>
                    <div class="input-group">
                      <input
                        type="text"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        class="form-control"
                        placeholder="Search here..."
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="search-mail">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : expenseList.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div class="table-responsive">
                    <table class="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Expense Type</th>
                          <th>Debit From</th>
                          <th>Date</th>
                          <th>Payment Type</th>
                          <th>Amount</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseList &&
                          expenseList.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.expense_type.type}</td>
                                <td>{list.users.name}</td>
                                <td>{list.date}</td>
                                <td>{list.payment_type}</td>
                                <td>{atob(list.amount)}</td>
                                <td>{list.note ? list.note : ""}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllExpense;
