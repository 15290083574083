import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import debounce from "lodash/debounce";
import Pagination from "../../../services/Pagination";
import "../../../css/sidebar.css";
import { showConfirmationDialog } from "../../../services/alert_service";
import { HospitalAPI } from "../../../api/hospitalAPI";

const Roles = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const rolePermissions = permissions.find(
    (item) => item.table_name === "roles"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getRoles = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getRoles(
      currentPage,
      itemsPerPage,
      searchQuery
    );
    setRoles(response.data.data);
    setTotalPages(response.data.total_page);
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    const confirmation = await showConfirmationDialog(
      "Are you sure you want to delete ?",
      "",
      "warning"
    );
    if (confirmation) {
      await HospitalAPI.deleteRoles(id);
      getRoles();
    }
  };

  const handleEditSubmit = async (data) => {
    setIsLoading(true);
    await HospitalAPI.updateRoles(selected, data);
    reset();
    setIsLoading(false);
    setModal(!modal);
    getRoles();
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = roles.find((item) => item.id === id);
    setSelected(selected.id);
    setValue("role", selected.role);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getRoles();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Roles</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Role.
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            {...register("role", {
                              required: "Role is Required.",
                            })}
                            name="role"
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter Role."
                          />
                        </div>
                      </div>
                      {errors.role && (
                        <p className="roleedir_err">{errors.role.message}</p>
                      )}

                      <div
                        className="form-group row"
                        style={{ marginTop: "35px" }}
                      >
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Roles</h1>
              </div>
              {(isAdmin || rolePermissions?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addroles">
                        <span className="btn btn-dark">Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="border-right pr-4 mr-4 mb-2 mb-xl-0 hidden-xs">
                    <p className="text-muted mb-1">Permanent</p>
                    <h5 className="mb-0">214</h5>
                  </div>
                  <div className="border-right pr-4 mr-4 mb-2 mb-xl-0">
                    <p className="text-muted mb-1">Contract</p>
                    <h5 className="mb-0">206</h5>
                  </div>
                  <div className="border-right pr-4 mr-4 mb-2 mb-xl-0">
                    <p className="text-muted mb-1">Total</p>
                    <h5 className="mb-0">420</h5>
                  </div>
                  <div className="mb-3 mb-xl-0">
                    <Link to="/addroles">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : roles.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Name</th>

                          {(isAdmin ||
                            rolePermissions?.edit ||
                            rolePermissions?.delete) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {roles &&
                          roles.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.role}</td>

                                <td>
                                  {(isAdmin || rolePermissions?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </button>
                                  )}
                                  {(isAdmin || rolePermissions?.delete) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
