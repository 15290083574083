import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AddExpenseType = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = async (data) => {
    setIsLoading(true);
    await HospitalAPI.addExpenseType(data);
    reset();
    setIsLoading(false);
  };

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div class="container-fluid">
          <div class="block-header">
            <div class="row clearfix">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Expense Types</h1>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-sm-12">
              <div class="card">
                <div class="body">
                  <form onSubmit={handleSubmit(handleAdd)}>
                    {isLoading && <span className="loader"></span>}
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        Expense Types
                        <span
                          className="text-danger"
                          style={{ margin: "-3px" }}
                        >
                          *
                        </span>
                      </label>
                      <div class="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("type", {
                            required: "Expense Type is Required.",
                          })}
                          class="form-control"
                          name="type"
                          placeholder="Enter expense type"
                        />
                        {errors.type && (
                          <p style={{ color: "red" }}>{errors.type.message}</p>
                        )}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label"></label>
                      <div class="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          class="btn btn-primary btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseType;
