import axios from "axios";
import { Apiurl } from "./apiRoute";
import { showAlert } from "./alert_service";

export const handleApiError = (error) => {
  if (error.response) {
    const statusCode = error.response.status;
    if (statusCode === 404) {
      return {
        error: "User Not Found",
        message: "User not found. Please check your credentials.",
      };
    } else if (statusCode === 403) {
      return {
        error: "Record Already Exists",
        message: "Record already exists",
      };
    } else if (statusCode === 412) {
      return {
        error: "Validation Error",
        message: error.response.data.message,
      };
    } else {
      return { error: "Backend Error", message: error.response.data };
    }
  } else if (error.request) {
    return {
      error: "Network Error",
      message: "No response received from the server",
    };
  } else {
    return { error: "Client Error", message: error.message };
  }
};

export const getMethod = async (url, params = {}) => {
  const login_token = localStorage.getItem("token");
  const headers = login_token ? { Authorization: `Bearer ${login_token}` } : {};
  try {
    const response = await axios.get(Apiurl.routePath + url, {
      headers,
      params,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      showAlert(
        error?.response?.data?.message !== undefined
          ? error?.response?.data?.message
          : "Internal Server Error",
        "error"
      );
      throw error.response.data;
    } else {
      showAlert(
        error?.message !== undefined ? error?.message : "Internal Server Error",
        "error"
      );
      throw error.message;
    }
  }
};

export const postMethod = async (url, params) => {
  const login_token = localStorage.getItem("token");
  if (login_token !== null) {
    var header = { Authorization: `Bearer ${login_token}` };
  }
  try {
    const response = await axios.post(Apiurl.routePath + url, params, {
      headers: header,
    });
    showAlert(
      response?.data?.message || response?.data?.data?.message,
      response?.data?.status === 200 ? "success" : "error"
    );
    return response?.data;
  } catch (err) {
    console.log("error-----", err.response);
    showAlert(
      err?.response?.data?.message ||
        err?.response?.message ||
        err?.response?.data?.data?.message ||
        "Internal Server Error",
      "error"
    );
    return err;
  }
};

export const deleteMethod = async (url) => {
  try {
    const login_token = localStorage.getItem("token");
    if (login_token !== null) {
      var headers = { Authorization: `Bearer ${login_token}` };
    }
    const response = await axios.delete(Apiurl.routePath + url, { headers });
    showAlert(
      response?.data?.data?.message,
      response?.data?.status === 200 ? "success" : "error"
    );
    return response.data;
  } catch (err) {
    const error = Promise.reject(err);
    showAlert(
      error?.message !== undefined ? error?.message : "Internal Server Error",
      "error"
    );
    return error;
  }
};
export const putMethod = async (url, params) => {
  try {
    const login_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${login_token}` };
    const response = await axios.put(Apiurl.routePath + url, params, {
      headers,
    });
    showAlert(
      response?.data?.data?.message,
      response?.data?.status === 200 ? "success" : "error"
    );
    return response;
  } catch (err) {
    const error = Promise.reject(err);
    showAlert(
      error?.message !== undefined ? error?.message : "Internal Server Error",
      "error"
    );
    return error;
  }
};
