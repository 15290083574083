import React, { useEffect, useState } from "react";
import "../../css/sidebar.css";
import { NavLink } from "react-router-dom";
import { Header } from "../utils/NameString.js";
import { getMethod } from "../../services/base_service.js";
import { Apiurl } from "../../services/apiRoute.js";

const Sidebar = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [permissions, setPermissions] = useState([]);

  const user = localStorage.getItem("username");
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getPermissions = async () => {
    const role_id = await localStorage.getItem("role_id");
    await getMethod(
      `${Apiurl.rolePermit}/role?role_id=${role_id}?checkForPermission=false`
    )
      .then((res) => {
        localStorage.setItem("permissions", JSON.stringify(res.data.data));
        setPermissions(res.data.data);
      })
      .catch((error) => {});
  };

  // const hasSettingsPermission = () => {
  //   const settingsTables = [
  //     "appointment_types",
  //     "roles",
  //     "role_permissions",
  //     "expense_types",
  //     "income_types",
  //   ];
  //   return settingsTables.some((table) =>
  //     permissions.some(
  //       (perm) => perm.table_name === table && (perm.read || perm.create)
  //     )
  //   );
  // };

  const hasPermission = (permissions, table_name, action) => {
    const permission = permissions.find(
      (perm) => perm.table_name === table_name
    );
    return permission && permission[action];
  };

  const hasTablePermission = (tableName) => {
    const permission = permissions.find(
      (perm) => perm.table_name === tableName
    );
    if (permission) {
      return permission.read || permission.create;
    }
    return false;
  };

  function toggleSidebar() {
    setSidebarVisible(!isSidebarVisible);
  }

  function toggleSubMenu(event) {
    event.preventDefault();
    const parentLi = event.target.closest("li");
    const submenu = parentLi.querySelector(".submenu");
    submenu.style.display =
      submenu.style.display === "block" ? "none" : "block";
  }

  const handleSidebarClose = () => {
    if (width < 1270) setSidebarVisible(!isSidebarVisible);
  };

  const closeSidebar = () => {
    if (width <= 1270) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }
  };

  const close = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    closeSidebar();
    close();
  }, [width]);

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div>
      {isSidebarVisible ? (
        <div
          id="left-sidebar"
          className="sidebar light_active sidebar_border scroll_sidebar"
        >
          <div className="navbar-brand">
            <div>
              <img
                src={Header.Logo_image}
                alt="hospital vLogo"
                className="img-fluid logo"
              />
              <span>{Header.Main_title}</span>
            </div>
            <button
              type="button"
              className=" btn btn-sm float-right show_button_opened"
              onClick={toggleSidebar}
            >
              <i className="fa fa-close"></i>
            </button>
          </div>
          <div className="user-account">
            <div className="user_div">
              <img
                src={Header.User_image}
                className="user-photo"
                alt="User Profile"
              />
            </div>
            <div className="dropdown">
              <span>{Header.Greetings}</span>
              <div className="dropdown-toggle user-name" data-toggle="dropdown">
                <strong>{user}</strong>
              </div>
            </div>
          </div>
          <nav
            id="left-sidebar-nav"
            className="sidebar-nav"
            style={{ fontFamily: "Nunito" }}
          >
            <ul
              id="main-menu"
              className="metismenu animation-li-delay collapsing.in"
            >
              <li className="header">{Header.Sub_title}</li>
              <li>
                <li>
                  <NavLink
                    to="/"
                    className="submenu-link"
                    onClick={handleSidebarClose}
                  >
                    <i className="fa fa-dashboard"></i>
                    Dashboard
                  </NavLink>
                </li>
              </li>
              {(isAdmin || hasTablePermission("users")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-user"></i>
                    <span>Users</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "users", "read")) && (
                        <NavLink
                          to="/users"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Users
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "users", "create")) && (
                        <NavLink
                          to="/addusers"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Users
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "users", "read")) && (
                        <NavLink
                          to="/user-collections"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          User Collections
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "transfer", "create")) && (
                        <NavLink
                          to="/transfer"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Transfer
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              <li>
                {/* {(isAdmin || */}
                {/* hasPermission(permissions, "collections", "read")) && ( */}
                <NavLink to="/ledger" onClick={handleSidebarClose}>
                  <i className="fa fa-inr"></i>
                  <span>Ledger</span>
                </NavLink>
                {/* )}*/}
              </li>
              {(isAdmin || hasTablePermission("appointments")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-calendar"></i>
                    <span>Appointment</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "appointments", "read")) && (
                        <NavLink
                          to="/appointments"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Appointments
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(
                          permissions,
                          "appointments",
                          "create"
                        )) && (
                        <NavLink
                          to="/addappointments"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Appointment
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("incomes")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-plus-circle"></i>
                    <span>Income</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {isAdmin && (
                        // || hasPermission(permissions, "incomes", "read")
                        <NavLink
                          to="/allincome"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Income
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "incomes", "create")) && (
                        <NavLink
                          to="/addincome"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Income
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("expenses")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-minus-circle"></i>
                    <span>Expense</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {isAdmin && (
                        // || hasPermission(permissions, "expenses", "read")
                        <NavLink
                          to="/allexpense"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Expense
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "expenses", "create")) && (
                        <NavLink
                          to="/addexpense"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Expense
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("policies")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-calendar"></i>
                    <span>Policies</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "policies", "read")) && (
                        <NavLink
                          to="/allpolicies"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Policies
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "policies", "create")) && (
                        <NavLink
                          to="/addpolicy"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Policy
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("hospitals")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-hospital-o"></i>
                    <span>Hospitals</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "hospitals", "read")) && (
                        <NavLink
                          to="/hospitals"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Hospitals
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "hospitals", "create")) && (
                        <NavLink
                          to="/addhospital"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Hospitals
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("patients")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-user-circle-o"></i>
                    <span>Patients</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "patients", "read")) && (
                        <NavLink
                          to="/patients"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Patient
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "patients", "create")) && (
                        <NavLink
                          to="/addpatient"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Patient
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("inventory")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-ambulance"></i>
                    <span>Inventory</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "inventory", "read")) && (
                        <NavLink
                          to="/add-inventory"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Inventory
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "inventory", "create")) && (
                        <NavLink
                          to="/inventory"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Inventory
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("agencies")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-id-badge"></i>
                    <span>Agency</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "agencies", "read")) && (
                        <NavLink
                          to="/agency"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          All Agencies
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "agencies", "create")) && (
                        <NavLink
                          to="/add-agency"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Agency
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("due_income")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-tasks"></i>
                    <span>Due Bills</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "due_income", "read")) && (
                        <NavLink
                          to="/due-bills"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Due Bills
                        </NavLink>
                      )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "due_income", "create")) && (
                        <NavLink
                          to="/add-due-bill"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          Add Due Bill
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
              {isAdmin && (
                // || hasSettingsPermission()
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-cogs"></i>
                    <span>Settings</span>
                  </a>
                  <ul className="submenu">
                    {(isAdmin || hasTablePermission("appointment_types")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu}
                        >
                          <i className="fa fa-book"></i>
                          <span>Appointment Types</span>
                        </a>
                        <ul className="submenu">
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "appointment_types",
                                "read"
                              )) && (
                              <NavLink
                                to="/alltypes"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                All Types
                              </NavLink>
                            )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "appointment_types",
                                "create"
                              )) && (
                              <NavLink
                                to="/addtypes"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                Add Types
                              </NavLink>
                            )}
                          </li>
                        </ul>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("roles")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu}
                        >
                          <i className="fa fa-user-md"></i>
                          <span>Roles</span>
                        </a>
                        <ul className="submenu">
                          <li>
                            {(isAdmin ||
                              hasPermission(permissions, "roles", "read")) && (
                              <NavLink
                                to="/roles"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                All Roles
                              </NavLink>
                            )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "roles",
                                "create"
                              )) && (
                              <NavLink
                                to="/addroles"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                Add Roles
                              </NavLink>
                            )}
                          </li>
                        </ul>
                      </li>
                    )}

                    {(isAdmin || hasTablePermission("role_permissions")) && (
                      <li>
                        <NavLink
                          to="/permissions"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          <i className="fa fa-check-square-o"></i>
                          Permissions
                        </NavLink>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("expense_types")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu}
                        >
                          <i className="fa fa-minus-circle"></i>
                          <span>Expense Types</span>
                        </a>
                        <ul className="submenu">
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "expense_types",
                                "read"
                              )) && (
                              <NavLink
                                to="/allexpensetype"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                All Exp Types
                              </NavLink>
                            )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "expense_types",
                                "create"
                              )) && (
                              <NavLink
                                to="/addexpensetype"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                Add Exp Type
                              </NavLink>
                            )}
                          </li>
                        </ul>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("income_types")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu}
                        >
                          <i className="fa fa-plus-circle"></i>
                          <span>Income Types</span>
                        </a>
                        <ul className="submenu">
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "income_types",
                                "read"
                              )) && (
                              <NavLink
                                to="/allincometype"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                All Income Types
                              </NavLink>
                            )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "income_types",
                                "create"
                              )) && (
                              <NavLink
                                to="/addincometype"
                                className="submenu-link"
                                onClick={handleSidebarClose}
                              >
                                Add Income Type
                              </NavLink>
                            )}
                          </li>
                        </ul>
                      </li>
                    )}
                    <li>
                      {isAdmin && (
                        <NavLink
                          to="/change-password"
                          onClick={handleSidebarClose}
                        >
                          <i className="fa fa-inr"></i>
                          <span>Change Password</span>
                        </NavLink>
                      )}
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      ) : (
        <div className="toggle_sidebar_button">
          <button
            type="button"
            className="btn-toggle-offcanvas show_button"
            onClick={toggleSidebar}
          >
            <i className="fa fa-align-left"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
