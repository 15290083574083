import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import { deleteMethod, getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { putMethod } from "../../../services/base_service";
import { Modal, ModalHeader } from "reactstrap";
import debounce from "lodash/debounce";
import Pagination from "../../../services/Pagination";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AllTypes = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const appointment_types = permissions.find(
    (item) => item.table_name === "appointment_types"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getAppointmentTypes = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getAppointmentTypes(
      currentPage,
      itemsPerPage,
      searchQuery
    );
    setTotalPages(response.data.total_page);
    setAppointmentTypes(response.data.data);
    setIsLoading(false);
  };

  const setEditModal = (id) => {
    setModal(true);
    const selectedd = appointmentTypes.find((item) => item.id === id);
    setSelected(selectedd.id);
    setValue("type", selectedd.type);
  };

  const handleEditSubmit = async (data) => {
    setIsLoading(true);
    await HospitalAPI.updateAppointmentType(selected, data);
    setIsLoading(false);
    reset();
    getAppointmentTypes();
    setModal(!modal);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    await HospitalAPI.deleteAppointmentType(id);
    setIsLoading(false);
    getAppointmentTypes();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getAppointmentTypes();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Appointment Type</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Name
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            {...register("type", {
                              required: "Appointment Type is Required.",
                            })}
                            name="type"
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter Type"
                          />
                          {errors.type && (
                            <p style={{ color: "red" }}>{errors.type}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{
                              backgroundColor: "#82b440",
                              marginRight: "30px",
                            }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => setModal(false)}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Appointment Types</h1>
              </div>
              {(isAdmin || appointment_types?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addtypes">
                        <span className="btn btn-dark">Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  {appointmentTypes.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-hover table-custom spacing5">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Appointment Type</th>
                              {(isAdmin ||
                                appointment_types?.edit ||
                                appointment_types?.delete) && <th>Actions</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {appointmentTypes &&
                              appointmentTypes.map((list, i) => (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{list.type}</td>
                                    <td>
                                      {(isAdmin || appointment_types?.edit) && (
                                        <button
                                          type="button"
                                          className="btn btn-default btn-sm"
                                          title="Edit"
                                          onClick={() => setEditModal(list.id)}
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>
                                      )}
                                      {(isAdmin ||
                                        appointment_types?.delete) && (
                                        <button
                                          type="button"
                                          className="btn btn-default btn-sm"
                                          title="Delete"
                                          onClick={() => handleDelete(list.id)}
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </>
                  ) : (
                    <p>No Records Found</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTypes;
