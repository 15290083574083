export const Apiurl = {
  // routePath: "http://localhost:7000/api/v1",
  routePath: "https://apihisab.genxel.in/api/v1",

  login: "/user/login",
  register: "/user/role",
  hospital: "/hospital",
  user: "/user",
  role: "/role",
  appointment: "/appointment",
  patient: "/patient",
  appointment_type: "/appointment-type",
  permission: "/rolePermit",
  transaction: "/transaction",
  expense_type: "/expense-type",
  income_type: "/income-type",
  expense: "/expense",
  income: "/income",
  collection: "/collection",
  transfer: "/transfer",
  policy: "/policy",
  rolePermit: "/rolePermit",
  agency: "/agency",
  inventory: "/inventory",
  dueIncome: "/due-income",
};
