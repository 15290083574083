import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import Navbar from "../Navbar";
import { Modal, ModalHeader } from "reactstrap";
import debounce from "lodash/debounce";
import Pagination from "../../../services/Pagination";
import { Link } from "react-router-dom";
import "../../../css/sidebar.css";
import { HospitalAPI } from "../../../api/hospitalAPI";
import { NoAuth } from "../../../api/noAuth";

const Appointments = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [appointments, setAppointments] = useState([]);
  const [modal, setModal] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [appointmentId, setAppointmentId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const appointment = permissions.find(
    (item) => item.table_name === "appointments"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getAppointments = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getAppointments(currentPage, itemsPerPage, searchQuery)
    setAppointments(response.data.data);
    setTotalPages(response.data.total_page);
    setIsLoading(false);
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = appointments.find((appointment) => appointment.id === id);
    setAppointmentId(selected.id);
    setValue("appointment_type_id", selected.appointment_type_id);
    setValue("amount", atob(selected.amount));
    setValue("date", selected.date);
    setValue("time", selected.time);
    setValue("appointment_status", selected.appointment_status);
  };

  const handleEditSubmit = async (data) => {
    setIsLoading(true);
    await HospitalAPI.updateAppointment(appointmentId, data)
    reset()
    setIsLoading(false);
    setModal(!modal);
    getAppointments();
  }

  const handleAppointStatus = async (e, id) => {
    e.preventDefault();
    if (!checkboxDisabled) {
      setCheckboxDisabled(true);
      let status = e.target.checked;
      if (status) {
        setIsLoading(true);
        await HospitalAPI.updateAppointment(id, { appointment_status: "completed" });
        setIsLoading(false);
        getAppointments();
        setCheckboxDisabled(false);
      }
    }
  };

  const getAppointmentTypes = async () => {
    const response = await NoAuth.getAppointmentTypes()
    setAppointmentTypes(response.data.data);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getAppointments();
  }, 300);

  useEffect(() => {
    getAppointmentTypes();
  }, []);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);


  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Appointment</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Appointment Types
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <select
                            id="appointment_type_id"
                            name="appointment_type_id"
                            {...register("appointment_type_id", {
                              required: "Appointment Type is Required.",
                            })}
                            className="form-control input-height user_editmodal"
                          >
                            <option value="">Select Appointment Types</option>
                            {appointmentTypes &&
                              appointmentTypes.map((type, index) => (
                                <option key={index} value={type.id}>
                                  {type.type}
                                </option>
                              ))}
                          </select>
                          {errors.appointment_type_id && (
                            <p
                              className="edit_appointment"
                              style={{ width: "250px" }}
                            >
                              {errors.appointment_type_id.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Date</label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="date"
                            {...register("date", {
                              required: "Date is Required.",
                            })}
                            name="date"
                            id="inputEmail3"
                            className="form-control user_editmodal"
                          />
                          {errors.date && (
                            <p className="edit_appointment">{errors.date.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Time</label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            className="form-control user_editmodal"
                            type="time"
                            {...register("time", {
                              required: "Time is Required.",
                            })}
                            name="time"
                            id="inputEmail3"
                          />
                          {errors.time && (
                            <p className="edit_appointment">{errors.time.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Appointment Status
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <select
                            name="appointment_status"
                            id="appointment_status"
                            {...register("appointment_status", {
                              required: "Appointment Status is Required.",
                            })}
                            className="form-control input-height user_editmodal"
                          >
                            <option value="">Select Status</option>
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                          </select>
                          {errors.appointment_status && (
                            <p className="edit_appointment">
                              {errors.appointment_status.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Amount
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="number"
                            {...register("amount", {
                              required: "Amount is Required.",
                            })}
                            name="amount"
                            id="inputEmail2"
                            className="form-control user_editmodal"
                            placeholder="Enter Amount"
                          />
                          {errors.amount && (
                            <p className="edit_appointment">{errors.amount.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{
                              backgroundColor: "grey",
                              marginLeft: "20px",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Appointments</h1>
              </div>
              {(isAdmin || appointment?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addappointments">
                        <span className="btn btn-dark">Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : appointments.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Appointment Type</th>
                          <th>Hospital</th>
                          <th>Patient</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Payment Status</th>
                          <th>Appointment Status</th>
                          <th>Payment Type</th>
                          <th>Amount</th>
                          <th>Adjustment Amount</th>
                          {(isAdmin || appointment?.edit) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {appointments &&
                          appointments.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.appointment_type.type}</td>
                                <td>{list.hospital.name}</td>
                                <td>{list.patient.name}</td>
                                <td>{list.date}</td>
                                <td>{list.time}</td>
                                <td>{list.payment_status}</td>
                                <td>{list.appointment_status}</td>
                                <td>
                                  {list.payment_type ? list.payment_type : "-"}
                                </td>
                                <td>{atob(list.amount)}</td>
                                <td>
                                  {list.adjustment_amount
                                    ? atob(list.adjustment_amount)
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {(isAdmin || appointment?.edit) && (
                                    <input
                                      type="checkbox"
                                      onClick={(e) =>
                                        handleAppointStatus(e, list.id)
                                      }
                                    />
                                  )}
                                  {(isAdmin || appointment?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : (
                <p>No Records Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointments;
