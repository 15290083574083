import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { NoAuth } from "../../../api/noAuth";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AddAppointment = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm();
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [patients, setPatients] = useState([]);
  const [users, setUsers] = useState([]);
  const [sortedPatients, setSortedPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const watchAppointmentStatus = watch("appointment_status");
  const watchPaymentStatus = watch("payment_status");

  const handleAddAppointment = async (data) => {
    if (
      data.appointment_status === "pending" ||
      data.payment_status === "pending"
    ) {
      delete data.collected_by;
      delete data.payment_type;
      delete data.note;
      delete data.adjustment_amount;
    }
    if (data.appointment_status === "pending") {
      delete data.payment_status;
    }
    setIsLoading(true);
    await HospitalAPI.addAppointment(data);
    setIsLoading(false);
    reset();
  };

  const filterPatient = (e) => {
    const sortPatient = patients.filter(
      (patient) => patient.hospital_id == e.target.value
    );
    setSortedPatients(sortPatient);
  };

  const getUsers = async () => {
    const response = await NoAuth.getUsers();
    setUsers(response.data.data);
  };

  const getPatientList = async () => {
    const response = await NoAuth.getPatients();
    setPatients(response.data.data);
  };

  const getAppointmentTypes = async () => {
    const response = await NoAuth.getAppointmentTypes();
    setAppointmentTypes(response.data.data);
  };

  const getHospitalList = async () => {
    const response = await NoAuth.getHospitals();
    setHospitals(response.data.data);
  };

  useEffect(() => {
    getAppointmentTypes();
    getHospitalList();
    getPatientList();
    getUsers();
    const currentDate = new Date().toISOString().split("T")[0];
    setValue("date", currentDate);
  }, [setValue]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1> Add New Appointment</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddAppointment)}>
                    {isLoading ? <span className="loader"></span> : ""}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Appointment Types
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="appointment_type_id"
                          name="appointment_type_id"
                          className="form-control"
                          {...register("appointment_type_id", {
                            required: "Appointment Type is Required.",
                          })}
                        >
                          <option value="">Select Appointment Type</option>
                          {appointmentTypes.map((type, i) => (
                            <option key={i} value={type.id}>
                              {type.type}
                            </option>
                          ))}
                        </select>
                        {errors.appointment_type_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.appointment_type_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Hospital Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="hospital_id"
                          name="hospital_id"
                          className="form-control"
                          {...register("hospital_id", {
                            required: "Hospital is Required.",
                          })}
                          onChange={filterPatient}
                        >
                          <option value="">Select Hospital</option>
                          {hospitals.map((hospital, i) => (
                            <option key={i} value={hospital.id}>
                              {hospital.name}
                            </option>
                          ))}
                        </select>
                        {errors.hospital_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.hospital_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Patient Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="patient_id"
                          name="patient_id"
                          className="form-control"
                          {...register("patient_id", {
                            required: "Patient is Required.",
                          })}
                        >
                          <option value="">Select Patient</option>
                          {sortedPatients.map((patient, i) => (
                            <option key={i} value={patient.id}>
                              {patient.name}
                            </option>
                          ))}
                        </select>
                        {errors.patient_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.patient_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          className="form-control"
                          name="date"
                          id="inputEmail3"
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Time
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="time"
                          {...register("time", {
                            required: "Time is Required.",
                          })}
                          className="form-control"
                          name="time"
                          id="inputEmail3"
                        />
                        {errors.time && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.time.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Appointment Status
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="appointment_status"
                          name="appointment_status"
                          className="form-control"
                          {...register("appointment_status", {
                            required: "Appointment Status is Required.",
                          })}
                        >
                          <option value="">Select Status</option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </select>
                        {errors.appointment_status && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.appointment_status.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {watchAppointmentStatus === "completed" && (
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Payment Status
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <select
                            id="payment_status"
                            name="payment_status"
                            className="form-control"
                            {...register("payment_status", {
                              required: "Payment Status is Required.",
                            })}
                          >
                            <option value="">Select Status</option>
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                          </select>
                          {errors.payment_status && (
                            <p
                              style={{
                                color: "red",
                                marginBottom: "0px",
                                fontSize: "13px",
                              }}
                            >
                              {errors.payment_status.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("amount", {
                            required: "Amount is Required.",
                          })}
                          className="form-control"
                          name="amount"
                          id="inputEmail2"
                          placeholder="Enter Amount"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.amount.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {watchAppointmentStatus === "completed" &&
                      watchPaymentStatus === "completed" && (
                        <>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              Collected By
                              <span
                                className="text-danger"
                                style={{ margin: "4px" }}
                              >
                                *
                              </span>
                            </label>
                            <div className="col-md-6 col-sm-10">
                              <select
                                id="collected_by"
                                name="collected_by"
                                className="form-control"
                                {...register("collected_by", {
                                  required: "Collected By is Required.",
                                })}
                              >
                                <option value="">Select User</option>
                                {users &&
                                  users.map((user, index) => (
                                    <option key={index} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.collected_by && (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {errors.collected_by.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              Payment Type
                              <span
                                className="text-danger"
                                style={{ margin: "4px" }}
                              >
                                *
                              </span>
                            </label>
                            <div className="col-md-6 col-sm-10">
                              <select
                                id="payment_type"
                                name="payment_type"
                                className="form-control"
                                {...register("payment_type", {
                                  required: "Payment Type is Required.",
                                })}
                              >
                                <option value="">Select types...</option>
                                <option value="cheque">Cheque</option>
                                <option value="cash">Cash</option>
                              </select>
                              {errors.payment_type && (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {errors.payment_type.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              Note
                              <span
                                className="text-danger"
                                style={{ margin: "4px" }}
                              >
                                *
                              </span>
                            </label>
                            <div className="col-md-6 col-sm-10">
                              <input
                                type="text"
                                {...register("note", {
                                  required: "Note is Required.",
                                })}
                                className="form-control"
                                name="note"
                                id="inputEmail2"
                                placeholder="Enter Adjustment Amount"
                              />
                              {errors.note && (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {errors.note.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              Adjustment Amount
                              <span
                                className="text-danger"
                                style={{ margin: "4px" }}
                              >
                                *
                              </span>
                            </label>
                            <div className="col-md-6 col-sm-10">
                              <input
                                type="number"
                                {...register("adjustment_amount", {
                                  required: "Adjustment Amount is Required.",
                                })}
                                className="form-control"
                                name="adjustment_amount"
                                id="inputEmail2"
                                placeholder="Enter Adjustment Amount"
                              />
                              {errors.adjustment_amount && (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {errors.adjustment_amount.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success"
                          style={{ backgroundColor: "#82b440" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAppointment;
