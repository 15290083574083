import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import Navbar from "./Navbar";
import { Modal, ModalHeader } from "reactstrap";
import Pagination from "../../services/Pagination";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import "../../css/sidebar.css";
import { showConfirmationDialog } from "../../services/alert_service";
import { NoAuth } from "../../api/noAuth";
import { UserAPI } from "../../api/userAPI";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [userId, setUserId] = useState(0);
  const [roles, setRoles] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const userPermission = permissions.find(
    (item) => item.table_name === "users"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";


  const getUsers = async () => {
    setIsLoading(true);
    const response = await UserAPI.getUsers(currentPage, 5, searchQuery,)
    setTotalPages(response.data.total_page);
    setUsers(response.data.data);
    setIsLoading(false)
  };

  const handleEditSubmit = async (data) => {
    setIsLoading(true);
    if (data.password === undefined || data.password.trim() === "") {
      delete data.password
    }
    await UserAPI.updateUser(userId, data)
    setIsLoading(false);
    reset()
    getUsers();
    setModal(!modal);
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = users.find((item) => item.id === id);
    setUserId(selected.id);
    setValue("role_id", selected.role_id);
    setValue("name", selected.name);
    setValue("mobile_no", selected.mobile_no);
    setValue("email", selected.email);
  };

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete?",
        "",
        "warning"
      );
      if (confirmation) {
        setIsLoading(true);
        await UserAPI.deleteUser(id)
        setIsLoading(false);
        getUsers();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (e, key) => {
    const value = e.target.value;
    let trimmedValue;
    if (key === "mobile_no") {
      trimmedValue = value.slice(0, 10);
      setValue("mobile_no", trimmedValue);
    }
  };

  const getRoles = async () => {
    const response = await NoAuth.getRoles()
    setRoles(response.data.data);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getUsers();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update User</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Role
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="role_id"
                          className="form-control"
                          {...register("role_id", {
                            required: "Role is Required.",
                          })}
                          style={{ width: "300px", marginLeft: "8px" }}
                        >
                          <option value="">Select roles...</option>
                          {roles.map((role, i) => (
                            <option key={i} value={role.id}>
                              {role.role}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.role_id && (
                        <p className="useredit_err">{errors.role_id.message}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Name
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            {...register("name", {
                              required: "Name is Required.",
                            })}
                            className="form-control"
                            name="name"
                            id="inputEmail3"
                            placeholder="Enter Name"
                            style={{ width: "300px" }}
                          />
                        </div>
                      </div>
                      {errors.name && (
                        <p className="useredit_err">{errors.name.message}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Mobile No.
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="number"
                            {...register("mobile_no", {
                              required: "Mobile Number is Required.",
                              pattern: {
                                value: /^\d{10}$/,
                                message:
                                  "Please enter a valid 10-digit Mobile Number",
                              },
                            })}
                            onChange={(e) => handleChange(e, "mobile_no")}
                            className="form-control"
                            name="mobile_no"
                            id="inputEmail3"
                            placeholder="Enter Mobile No."
                            style={{ width: "300px" }}
                          />
                        </div>
                      </div>
                      {errors.mobile_no && (
                        <p className="useredit_err">{errors.mobile_no.message}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Email
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="email"
                            {...register("email", {
                              required: "Email is Required.",
                              pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message:
                                  "Please enter a valid email address",
                              },
                            })}
                            className="form-control"
                            name="email"
                            id="inputEmail3"
                            placeholder="Enter Email"
                            style={{ width: "300px" }}
                          />
                        </div>
                      </div>
                      {errors.email && (
                        <p className="useredit_err">{errors.email.message}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Password
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="password"
                            name="password"
                            {...register("password", {
                              pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/,
                                message: "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one special character, and one number.",
                              },
                            })}
                            className="form-control"
                            placeholder="Enter your password"
                            style={{ width: "300px" }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Users</h1>
              </div>
              {(isAdmin || userPermission?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addusers">
                        <span className="btn btn-dark">Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        className="form-control"
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : users.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Role</th>
                          <th>Name</th>
                          <th>Mobile No.</th>
                          <th>Email</th>
                          {(isAdmin ||
                            userPermission?.edit ||
                            userPermission?.delete) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((list, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{list.role.role}</td>
                              <td>{list.name}</td>
                              <td>{list.mobile_no}</td>
                              <td>{list.email}</td>
                              <td>
                                {(isAdmin || userPermission?.edit) && (
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm"
                                    title="Edit"
                                    onClick={() => setEditModal(list.id)}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                )}
                                {(isAdmin || userPermission?.delete) && (
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm"
                                    title="Delete"
                                    onClick={() => handleDelete(list.id)}
                                  >
                                    <i className="fa fa-trash-o"></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : (
                <p>No Records Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
