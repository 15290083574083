import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "./Navbar";
import "../../css/sidebar.css";
import { UserAPI } from "../../api/userAPI";
import { NoAuth } from "../../api/noAuth";

const Transfer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTransfer = async (data) => {
    data.payment_type = "cash"
    setIsLoading(true);
    await UserAPI.transferMoney(data);
    reset()
    setIsLoading(false);
  };

  const getUsers = async () => {
    const response = await NoAuth.getUsers()
    setUsers(response.data.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Transfer Amount</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleTransfer)}>
                    {isLoading ? <span className="loader"></span> : ""}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        From
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="from"
                          name="from"
                          className="form-control"
                          {...register("from", {
                            required: "Sender is Required.",
                          })}
                        >
                          <option value="">Select Sender</option>
                          {users.map((user, i) => (
                            <option key={i} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                        {errors.from && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.from.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        To
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="to"
                          name="to"
                          className="form-control"
                          {...register("to", {
                            required: "Receiver is Required.",
                          })}
                        >
                          <option value="">Select Receiver</option>
                          {users.map((user, i) => (
                            <option key={i} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                        {errors.to && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.to.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          name="amount"
                          {...register("amount", {
                            required: "Amount is Required.",
                          })}
                          className="form-control"
                          placeholder="Enter Amount to Transfer"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.amount.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                        <span
                          className="text-danger"
                          style={{ margin: "4px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("note", {
                            required: "Note is Required.",
                          })}
                          name="note"
                          className="form-control"
                          placeholder="Enter Note"
                        />
                        {errors.note && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.note.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
