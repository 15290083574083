import axios from "axios";
import { toast } from "react-toastify";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.log("Unexpected API error:", error);
      toast.error(
        "Unexpected error while communicating with the server. Please try again later."
      );
    } else {
      toast.error(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.message ||
          "Error while communicating with the server."
      );
    }
    return Promise.reject(error);
  }
);
