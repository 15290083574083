import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { UserAPI } from "../../api/userAPI";

const Dashboard = () => {
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getDashboard = async () => {
    setIsLoading(true);
    const response = await UserAPI.getDashboard();
    setIsLoading(false);
    setTotalExpense(response?.data[0]?.totalExpense || 0);
    setTotalIncome(response?.data[0]?.totalIncome || 0);
    setNetAmount(response?.data[0]?.totalAmount || 0);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix row-deck">
            <div className="col-lg-4 col-md-8">
              <div className="card">
                <div className="body">
                  <div className="font-15">Total Income</div>
                  <div className="py-4 m-0 h3">
                    {totalIncome !== null ? totalIncome : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8">
              <div className="card">
                <div className="body">
                  <div className="font-15">Total Expense</div>
                  <div className="py-4 m-0 h3">
                    {totalExpense !== null ? totalExpense : 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-8">
              <div className="card">
                <div className="body">
                  <div className="font-15">Net Amount</div>
                  <div className="py-4 m-0 h3">
                    {netAmount !== null ? netAmount : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? <span className="loader"></span> : ""}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
