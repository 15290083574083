import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import jsPDF from "jspdf";
import Pagination from "../../services/Pagination";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { UserAPI } from "../../api/userAPI";

const UserCollections = () => {
  const [collections, setCollections] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const getCollections = async () => {
    setIsLoading(true);
    const response = await UserAPI.getUserCollections();
    const filtered = response.data.filter((collection) =>
      collection.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCollections(response.data);
    setFilteredCollections(filtered);
    setIsLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleGeneratePdf = async (id) => {
    setIsLoading(true);

    const response = await UserAPI.getCollectionPerUser(id);

    const ledgerData = response.data;

    const pdf = new jsPDF();
    pdf.text("User Ledger", 20, 15);

    const headers = [
      "Date",
      "Transaction Type",
      "Amount",
      "Payment Type",
      "Type",
    ];
    const headerY = 25;
    const headerHeight = 7;
    const fontSize = 10;

    const headerX = 20;
    const headerWidth = headers.length * 35;
    pdf.setFillColor(200, 200, 200);
    pdf.rect(
      headerX,
      headerY - headerHeight + 1,
      headerWidth,
      headerHeight,
      "F"
    );

    pdf.setTextColor(0, 0, 0);

    headers.forEach((header, index) => {
      pdf.setFontSize(fontSize);
      pdf.text(header, 20 + index * 35, headerY - 2);
    });

    const startY = headerY + headerHeight;
    const lineX = 20;
    const lineY = startY - 5;
    const lineHeight = 8;
    const lineWidth = headerWidth;

    pdf.rect(lineX, lineY, lineWidth, lineHeight, "S");
    pdf.setFontSize(fontSize);
    ledgerData.forEach((entry, index) => {
      const rowY = startY + index * 8;
      pdf.text(entry.createdAt.substring(0, 10), 20, rowY);
      pdf.text(entry.transaction_type, 20 + 35 * 1, rowY);
      pdf.text(atob(entry.amount), 20 + 35 * 2, rowY);
      pdf.text(entry.payment_type, 20 + 35 * 3, rowY);
      pdf.text(entry.type, 20 + 35 * 4, rowY);
      const lineY = rowY + 3;
      pdf.rect(lineX, lineY, lineWidth, lineHeight, "S");
    });
    pdf.save("user_ledger.pdf");
    setIsLoading(false);
  };

  const slicedCollections = filteredCollections.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getCollections();
  }, [searchQuery]);

  return (
    <div>
      <Navbar />

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>User Collections</h1>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div class="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div class="mb-3 mb-xl-0">
                    <Link to="/transfer">
                      <span class="btn btn-dark">Transfer</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : slicedCollections.length === 0 ? (
                <p>No Records Found.</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Collected By</th>
                          <th>Amount</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {slicedCollections &&
                          slicedCollections.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.name}</td>
                                <td>{list.totalAmount}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm"
                                    title="Edit"
                                    onClick={() =>
                                      handleGeneratePdf(list.collected_by)
                                    }
                                  >
                                    <i className="fa fa-download"></i>
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCollections;
