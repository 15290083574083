import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "./Navbar";
import { Modal, ModalHeader } from "reactstrap";
import Pagination from "../../services/Pagination";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import { showConfirmationDialog } from "../../services/alert_service";
import { HospitalAPI } from "../../api/hospitalAPI";

const Hospitals = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [hospitalList, setHospitalList] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const hospitals = permissions.find((item) => item.table_name === "hospitals");
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const delayedSearch = debounce(() => {
    gethospitals();
  }, 300);

  const gethospitals = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getHospitals(
      currentPage,
      itemsPerPage,
      searchQuery
    );
    setHospitalList(response.data.data);
    setTotalPages(response.data.total_page);
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        setIsLoading(true);
        await HospitalAPI.deleteHospital(id);
        setIsLoading(false);
        gethospitals();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = hospitalList.find((hospital) => hospital.id === id);
    setId(selected.id);
    setValue("name", selected.name);
    setValue("mobile_no", selected.mobile_no);
    setValue("contact_person", selected.contact_person);
  };

  const handleEditSubmit = async (data) => {
    setIsLoading(true);
    await HospitalAPI.updateHospital(id, data);
    setIsLoading(false);
    gethospitals();
    setModal(!modal);
  };

  
  const handleChange = (e, key) => {
    const value = e.target.value;
    let trimmedValue;
    if (key === "mobile_no") {
      trimmedValue = value.slice(0, 10);
      setValue("mobile_no", trimmedValue);
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Hospitals</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Hospital Name
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            {...register("name", {
                              required: "Name is Required.",
                            })}
                            className="form-control"
                            name="name"
                            style={{ width: "300px" }}
                            placeholder="Enter hospital name"
                          />
                        </div>
                      </div>
                      {errors.name && (
                        <p className="edit_hospital">{errors.name.message}</p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Contact Person
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            name="contact_person"
                            {...register("contact_person", {
                              required: "Contact Person's Name is Required.",
                            })}
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter Contact Person name"
                          />
                        </div>
                      </div>
                      {errors.contact_person && (
                        <p className="edit_hospital">
                          {errors.contact_person.message}
                        </p>
                      )}
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Mobile No.
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            name="mobile_no"
                            {...register("mobile_no", {
                              required: "Mobile Number is Required.",
                              pattern: {
                                value: /^\d{10}$/,
                                message:
                                  "Please enter a valid 10-digit Mobile Number",
                              },
                            })}
                            onChange={(e) => handleChange(e, "mobile_no")}
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter mobile no."
                          />
                        </div>
                      </div>
                      {errors.mobile_no && (
                        <p className="edit_hospital">
                          {errors.mobile_no.message}
                        </p>
                      )}
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{
                              backgroundColor: "#82b440",
                              marginRight: "30px",
                            }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => setModal(false)}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Hospitals</h1>
              </div>
              {(isAdmin || hospitals?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addhospital">
                        <span className="btn btn-dark">Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : hospitalList.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Name</th>
                          <th>Contact Person</th>
                          <th>Mobile No.</th>
                          {(isAdmin ||
                            hospitals?.edit ||
                            hospitals?.delete) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {hospitalList &&
                          hospitalList.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list?.name}</td>
                                <td>{list?.contact_person}</td>
                                <td>{list?.mobile_no}</td>
                                <td>
                                  {(isAdmin || hospitals?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </button>
                                  )}
                                  {(isAdmin || hospitals?.delete) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hospitals;
