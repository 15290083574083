import { api } from "./config";

export const NoAuth = {
  async getRoles() {
    const response = await api.get(`/role?checkForPermission=false`);
    return response.data;
  },
  async getUsers() {
    const response = await api.get("/user/role?checkForPermission=false");
    return response.data;
  },
  async getAppointmentTypes() {
    const response = await api.get(
      "/appointment-type?checkForPermission=false"
    );
    return response.data;
  },
  async getHospitals() {
    const response = await api.get("/hospital?checkForPermission=false");
    return response.data;
  },
  async getPatients() {
    const response = await api.get("/patient?checkForPermission=false");
    return response.data;
  },
  async getIncomeTypes() {
    const response = await api.get("/income-type?checkForPermission=false");
    return response.data;
  },
  async getExpenseTypes() {
    const response = await api.get("/expense-type?checkForPermission=false");
    return response.data;
  },
  async getAgencies() {
    const response = await api.get("/agency?checkForPermission=false");
    return response.data;
  },
};
