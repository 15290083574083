import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserAPI } from "../../api/userAPI";
import "../../css/sidebar.css";
import { Header } from "../utils/NameString";
import { postMethod } from "../../services/base_service";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (data) => {
    try {
      setIsLoading(true);
      const response = await postMethod("/user/login", data);
      setIsLoading(false);
      console.log("response of login", response.data);
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isAdmin", response.data.isAdmin);
        localStorage.setItem("username", response.data.name);
        if (
          response.data.permissions[0] &&
          response.data.permissions[0].role_id
        ) {
          localStorage.setItem(
            "role_id",
            response.data?.permissions[0].role_id
          );
        }
        localStorage.setItem(
          "permissions",
          JSON.stringify(response.data.permissions)
        );

        reset();
        await navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (e, key) => {
    const value = e.target.value;
    let trimmedValue;
    if (key === "mobile_no") {
      trimmedValue = value.slice(0, 10);
      setValue("mobile_no", trimmedValue);
    }
  };

  return (
    <div>
      <div id="body" className="theme-green">
        <div className="auth-main">
          <div className="auth_div vivify fadeIn">
            <div className="auth_brand">
              <a className="navbar-brand" href="#">
                <img
                  src={Header.Logo_image}
                  width="50"
                  className="d-inline-block align-top mr-2"
                  alt="photos"
                />
                {Header.Main_title}
              </a>
            </div>
            <div className="card">
              <div className="header">
                <p className="lead">Login to your account</p>
              </div>
              <div className="body">
                <form
                  className="form-auth-small"
                  onSubmit={handleSubmit(handleLogin)}
                >
                  {isLoading ? (
                    <span className="loader" style={{ zIndex: "1000" }}></span>
                  ) : (
                    ""
                  )}
                  <div className="form-group c_form_group">
                    <label>Mobile No.</label>
                    <input
                      type="number"
                      {...register("mobile_no", {
                        required: "Mobile Number is Required.",
                        pattern: {
                          value: /^\d{10}$/,
                          message:
                            "Please enter a valid 10-digit Mobile Number",
                        },
                      })}
                      onChange={(e) => handleChange(e, "mobile_no")}
                      d
                      className="form-control"
                      name="mobile_no"
                      id="inputEmail3"
                      placeholder="Enter Mobile No."
                    />
                  </div>
                  {errors.mobile_no && (
                    <p className="loginerrorr">{errors.mobile_no.message}</p>
                  )}
                  <div className="form-group c_form_group">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      {...register("password", {
                        required: "Password is Required.",
                      })}
                      className="form-control"
                      placeholder="Enter your password"
                    />
                  </div>
                  {errors.password && (
                    <p className="loginerrorr">{errors.password.message}</p>
                  )}
                  <button
                    type="submit"
                    className="btn btn-dark btn-lg btn-block"
                  >
                    LOGIN
                  </button>
                  {/* <div className="bottom">
                    <span>
                      Don't have an account?
                      <Link to="/addusers">Register</Link>
                    </span>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
          <div className="animate_lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
