import React, { useEffect, useState } from "react";
import Pagination from "../../../services/Pagination";
import Navbar from "../Navbar";
import { getMethod } from "../../../services/base_service";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AllPolicies = () => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 10;

  const getPolicies = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getPolicies(
      currentPage,
      itemsPerPage,
      searchQuery
    );

    setTotalPages(response.data.total_page);
    setPolicies(response.data.data);
    setIsLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getPolicies();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Policies</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/addpolicy">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : policies.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Policy Name </th>
                          <th>Reminder Type </th>
                          <th>Reminder Date </th>
                          <th>Next Reminder Date </th>
                        </tr>
                      </thead>
                      <tbody>
                        {policies &&
                          policies.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.policy_name}</td>
                                <td>{list.reminder_type}</td>
                                <td>{list.reminder_date.substring(0, 10)}</td>
                                <td>
                                  {list.next_reminder_date.substring(0, 10)}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPolicies;
