import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AddPolicy = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = async (data) => {
    setIsLoading(true);
    await HospitalAPI.addPolicy(data);
    reset();
    setIsLoading(false);
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setValue("date", currentDate);
  }, [setValue]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1> Add New Policy</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAdd)}>
                    {isLoading && <span className="loader"></span>}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Policy Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          name="policy_name"
                          {...register("policy_name", {
                            required: "Policy Name is Required.",
                          })}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter policy name"
                        />
                        {errors.policy_name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.policy_name.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Reminder Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          name="reminder_date"
                          {...register("reminder_date", {
                            required: "Reminder Date is Required.",
                          })}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter reminder date"
                        />
                        {errors.reminder_date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.reminder_date.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Reminder Type
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="reminder_type"
                          {...register("reminder_type", {
                            required: "Reminder Type is Required.",
                          })}
                          className="form-control input-height"
                        >
                          <option value="">Select Types...</option>
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                        {errors.reminder_type && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.reminder_type.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success mt-2"
                          style={{ backgroundColor: "#82b440" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPolicy;
