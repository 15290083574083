import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { NoAuth } from "../../../api/noAuth";
import { HospitalAPI } from "../../../api/hospitalAPI";

const AddIncome = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [incomeTypes, setIncomeTypes] = useState([]);
  const [Users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddIncome = async (data) => {
    data.payment_type = "cash";
    setIsLoading(true);
    await HospitalAPI.addIncome(data);
    reset();
    setIsLoading(false);
  };

  const getIncomeTypes = async () => {
    const response = await NoAuth.getIncomeTypes();
    setIncomeTypes(response.data.data);
  };

  const getUsers = async () => {
    const response = await NoAuth.getUsers();
    setUsers(response.data.data);
  };

  useEffect(() => {
    getIncomeTypes();
    getUsers();
    const currentDate = new Date().toISOString().split("T")[0];
    setValue("date", currentDate);
  }, [setValue]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Income</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddIncome)}>
                    {isLoading ? <span className="loader"></span> : ""}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Select Credit To
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="credit_to"
                          id="credit_to"
                          {...register("credit_to", {
                            required: "Receiver is Required.",
                          })}
                          className="form-control input-height"
                        >
                          <option value="">Select user...</option>
                          {Users &&
                            Users.map((user, i) => (
                              <option key={i} value={user.id}>
                                {user.name}
                              </option>
                            ))}
                        </select>
                        {errors.credit_to && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.credit_to.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Select Income Type
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="income_type_id"
                          name="income_type_id"
                          {...register("income_type_id", {
                            required: "Income Type is Required.",
                          })}
                          className="form-control input-height"
                        >
                          <option value="">Select types...</option>
                          {incomeTypes &&
                            incomeTypes.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.type}
                              </option>
                            ))}
                        </select>
                        {errors.income_type_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.income_type_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("amount", {
                            required: "Amount is Required.",
                          })}
                          className="form-control"
                          name="amount"
                          id="inputEmail3"
                          placeholder="Enter Amount"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.amount.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          name="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter Date"
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("note", {
                            required: "Note is Required.",
                          })}
                          className="form-control"
                          name="note"
                          id="inputEmail3"
                          placeholder="Enter note"
                        />
                        {errors.note && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.note.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIncome;
