import React, { useEffect, useState } from "react";
import Pagination from "../../../services/Pagination";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { HospitalAPI } from "../../../api/hospitalAPI";

const IncomeType = () => {
  const [incomeTypes, setIncomeTypes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const getIncomeTypes = async () => {
    setIsLoading(true);
    const response = await HospitalAPI.getIncomeType(
      currentPage,
      itemsPerPage,
      searchQuery
    );
    setTotalPages(response.data.total_page);
    setIncomeTypes(response.data.data);
    setIsLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getIncomeTypes();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <Navbar />

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Income Types</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/addincometype">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        placeholder="Search here..."
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="search-mail">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : incomeTypes.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Income Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {incomeTypes &&
                          incomeTypes.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{list.type}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeType;
